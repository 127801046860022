<template>
  <div class="allTemplate">
    <div class="pageNav">
      <div class="navMain" v-if="tabsShow">
        <div
          class="nav-items"
          :class="changeIndex == index ? 'active' : ''"
          v-for="(item, index) in tabList"
          :key="index"
          @click="changeTab(item.name, item.id, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="navMain" v-if="tabsShow2">
        <div
          class=""
          :class="[
            'nav-items',
            changeIndex2 == item.title ? 'active' : '',
            item.icon ? 'active-up-link' : '',
          ]"
          v-for="(item, index) in tabList2"
          :key="index"
          @click="changeTab2(index, item)"
        >
          <img class="page-icon" v-if="item.icon" :src="item.icon" alt="" />
          {{ item.name }}
        </div>
      </div>
      <!-- 设备云 -->
      <div class="navMain" v-if="tabsShow3">
        <div
          class="nav-items"
          :class="changeIndex3 == item.id ? 'active' : ''"
          v-for="(item, index) in tabList3"
          :key="item.id"
          @click="changeTab3(item.icon, item.id, index)"
        >
          {{ item.name }}
        </div>
        <!-- 测试 -->
        <!-- <div class="nav-items" @click="changeTab3('shuifei')">水肥一体</div> -->
        <!-- 测试结束 -->
      </div>
    </div>
    <div class="btn-views">
      <div class="r-add" @click="addLands" v-if="addShow">
        <img src="../assets/image/land/addland.png" class="add-icons" />
        <slot name="addName"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { getEquipmentGroupApi } from "@/request/api";

export default {
  props: {
    addShow: {
      type: Boolean,
      default: false,
    },
    tabsShow: {
      type: Boolean,
      default: false,
    },
    tabsShow2: {
      type: Boolean,
      default: false,
    },
    tabsShow3: {
      type: Boolean,
      default: false,
    },
    tabList: {
      type: Array,
    },
    tabList2: {
      type: Array,
    },
    tabList3: {
      type: [Array, Object],
    },
    changeIndex3: {
      type: [String, Number],
    },
  },
  data() {
    return {
      changeIndex: 0,
      changeIndex2: 0,
    };
  },
  created() {
    this.changeIndex2 = this.$route.meta.title;
  },

  methods: {
    async getEquipmentGroupApi() {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200) {
        this.tabList3 = data;
        if (!sessionStorage.getItem("equipmentName")) {
          sessionStorage.setItem("equipmentId", data[0].id);
          sessionStorage.setItem("equipmentName", data[0].icon);
        }
        this.changeIndex3 = this.$route.query.id;
        if (this.changeIndex3 === undefined) {
          this.changeIndex3 = this.tabList3["0"].id;
        }
      }
    },
    addLands() {
      this.$emit("addMeans");
    },
    changeTab(name, id, index) {
      this.changeIndex = index;
      sessionStorage.setItem("childTitle", this.changeIndex);
      this.$emit("changeTab", { id, name });
    },
    changeTab2(id, { title }) {
      this.changeIndex2 = title;
      sessionStorage.setItem("childTitle2", this.changeIndex2);
      this.$emit("changeTab2", id);
    },
    changeTab3(icon, id) {
      this.$emit("changeTab3", { id, icon });
    },
  },
};
</script>

<style lang="less">
.allTemplate {
  width: 100%;
  display: flex;
  flex-direction: column;
  .pageNav {
    width: 100%;
    height: 56px;
    background-color: #3e90e5;
    margin: 30px 0;
    padding: 8px 48px 0;
    .navMain {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0 auto;
      .nav-items {
        width: 186px;
        height: 48px;
        color: #fff;
        font-size: 18px;
        background-color: #2d6fb4;
        margin-right: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 0.8;
        }
        .page-icon {
          width: 24px;
          height: 26px;
          margin-right: 13px;
        }
        &.active {
          background-color: #071a2c;
        }
        &.active-up-link {
          background-color: #ff9600 !important;
        }
      }
    }
  }
  .btn-views {
    width: 100%;
    padding: 0 48px;
    display: flex;
    justify-content: flex-end;
    .r-add {
      width: 143px;
      height: 40px;
      background-color: #3e90e5;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
      .add-icons {
        width: 18px;
        height: 18px;
        margin-right: 13px;
      }
    }
  }

  .tabs {
    border: 1px solid #385576;
    display: flex;
    .tab-items {
      width: 178px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      border-right: 1px solid #385576;
      background-color: #0e2138;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:last-of-type {
        border-right: none;
      }
    }

    .tab-items.active {
      background-color: #3e90e5;
    }
  }
}
</style>
